import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ellocale from 'element-ui/lib/locale';
import en from 'element-ui/lib/locale/lang/en'
import zhCN from 'element-ui/lib/locale/lang/zh-CN'
import zhtw from 'element-ui/lib/locale/lang/zh-TW'
import ja from 'element-ui/lib/locale/lang/ja'
import es from 'element-ui/lib/locale/lang/es'

Vue.use(VueI18n)
function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
      if(locale=="zh") messages[locale]=Object.assign(messages[locale],zhCN)
      if(locale=="zh-HK") messages[locale]=Object.assign(messages[locale],zhtw)
      if(locale=="en") messages[locale]=Object.assign(messages[locale],en)
      if(locale=="es") messages[locale]=Object.assign(messages[locale],es)
      if(locale=="ja") messages[locale]=Object.assign(messages[locale],ja)
    }
  })
  return messages
}
const i18n =  new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'zh',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh',
  messages: loadLocaleMessages(),
  silentTranslationWarn: false
})
ellocale.i18n((key, value) => i18n.t(key, value))

export default i18n