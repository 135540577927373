import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth';

class FirebaseAuthBackend {
    constructor(firebaseConfig) {
        if (firebaseConfig) {
            // Initialize Firebase
            this.app = initializeApp(firebaseConfig);
            this.auth = getAuth(this.app);

            // Set up an observer on Auth object
            onAuthStateChanged(this.auth, (user) => {
                if (user) {
                    sessionStorage.setItem("authUser", JSON.stringify(user));
                } else {
                    sessionStorage.removeItem('authUser');
                }
            });
        }
    }

    /**
     * Registers the user with given details
     */
    registerUser = (username, password) => {
        return new Promise((resolve, reject) => {
            createUserWithEmailAndPassword(this.auth, username, password)
                .then((userCredential) => {
                    resolve(userCredential.user);
                })
                .catch((error) => {
                    reject(this._handleError(error));
                });
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (username, password) => {
        return new Promise((resolve, reject) => {
            signInWithEmailAndPassword(this.auth, username, password)
                .then((userCredential) => {
                    resolve(userCredential.user);
                })
                .catch((error) => {
                    reject(this._handleError(error));
                });
        });
    }

    /**
     * Forget Password for user with given details
     */
    forgetPassword = (username) => {
        return new Promise((resolve, reject) => {
            sendPasswordResetEmail(this.auth, username, { url: window.location.protocol + "//" + window.location.host + "/login" })
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(this._handleError(error));
                });
        });
    }

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            signOut(this.auth)
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(this._handleError(error));
                });
        });
    }

    setLoggedInUser = (user) => {
        sessionStorage.setItem("authUser", JSON.stringify(user));
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!sessionStorage.getItem('authUser'))
            return null;
        return JSON.parse(sessionStorage.getItem('authUser'));
    }

    /**
     * Handle the error
     * @param {*} error 
     */
    _handleError(error) {
        return error.message;
    }
}

let _fireBaseBackend = null;

/**
 * Initialize the backend
 * @param {*} config 
 */
const initFirebaseBackend = (config) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    return _fireBaseBackend;
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
}

export { initFirebaseBackend, getFirebaseBackend };
