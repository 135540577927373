<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${this.$t("title")}` : this.$t("title");
    },
  },
  created() {
    var that = this;
    that.getotpstatus();
    if (localStorage.getItem("serial")) {
      let serialstatus = localStorage.getItem("serial")
      if (serialstatus == 1) {
        let route = that.$route.path;
        if (route.includes("/login") || route.includes("/register")) {
        } else {
          if (localStorage.getItem("user")) {
            that
              .$confirm("当前设置已经开启了串口读卡器，是否立刻连接", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                that.openserial();
              })
              .catch(() => { });
          }
        }
      }
    }
    console.log(that.$route.path);
    // if (localStorage.getItem("serial")) {
    //   let serialstatus = localStorage.getItem("serial");
    //   if (serialstatus == 1) {
    //     let route = that.$route.path;
    //     if (!route.includes("/login") && !route.includes("/register")) {
    //       if (localStorage.getItem("user")) {
    //         that.openserial();
    //       }
    //     }
    //   }
    // }
    // if (localStorage.getItem("user")) {
    let userinfo = JSON.parse(localStorage.getItem("user"));
    const cardreader = that.mqtt.connect("wss://mqtt.aicoiot.com:8084/mqtt", {
      clientId: that.hostname + "_" + uuidv4(),
      clean: true,
      userName: that.hostname,
    });
    cardreader.on("connect", () => {
      console.log(`${Date()}:Connected to MQTT Broker`);
    });
    cardreader.subscribe(["notify/" + that.hostname], (error) => {
      console.log(error);
      if (!error) {
        that.$message({
          message:
            that.$t("global.ws.text") +
            " " +
            that.$t("menuitems.clouds.nfcreader.connect"),
          type: "success",
        });
      } else {
        that.$message({
          message:
            that.$t("global.ws.text") +
            " " +
            that.$t("menuitems.clouds.nfcreader.connectfail"),
          type: "error",
        });
      }
    });
    cardreader.on("message", (topic, message) => {
      // console.log(`Received message on ${topic}: ${message.toString()}`)
      let mqttmessage = JSON.parse(message.toString());
      let route = that.$route.path;

      if (route.includes("login") || route.includes("admins")) {
        that.$bus.$emit('message', mqttmessage);
      }
      if (route.includes("user/detail") && mqttmessage.Action === "ReadUID") {
        that.bindcard(mqttmessage.Uid);
      } else if (mqttmessage.Action === "ReadUID") {
        that.searchcarduid(mqttmessage.Uid);
      } else if (mqttmessage.Action === "Offline") {
        that.$message({
          message:
            that.$t("menuitems.clouds.nfcreader.text") +
            " " +
            that.$t("menuitems.clouds.nfcreader.disconnect"),
          type: "warning",
        });
      } else if (mqttmessage.Action === "notice") {
        mqttmessage["time"] = Date.parse(new Date());
        mqttmessage["read"] = 0;

        if (mqttmessage.type2 === "device") {
          that.$notify({
            title: that.$t("global.notice." + mqttmessage.status),
            message:
              that.$t("global.notice.device", { device: mqttmessage.edid }) +
              " " +
              that.$t("global.notice." + mqttmessage.type1),
            type: mqttmessage.status,
          });
        } else if (mqttmessage.type2 === "service") {
          that.$notify({
            title: that.$t("global.notice." + mqttmessage.status),
            message:
              that.$t("global.notice.service", {
                service: that.$t(
                  "global.notice.server." + mqttmessage.service
                ),
              }) +
              " " +
              that.$t("global.notice." + mqttmessage.type1),
            type: mqttmessage.status,
            duration: 0,
          });
        }
      } else if (mqttmessage.type2 === "stock") {
        that.$notify({
          title: that.$t("global.notice." + mqttmessage.status),
          message: that.$t("global.notice.stock", {
            edid: mqttmessage.edid,
            stock: mqttmessage.stock,
          }),
          type: mqttmessage.status,
        });
      } else if (mqttmessage.type2 === "sensor") {
        that.$notify({
          title: that.$t("global.notice." + mqttmessage.status),
          message: that.$t("global.notice.sensor", {
            edid: mqttmessage.edid,
            num: mqttmessage.num,
          }),
          type: mqttmessage.status,
        });
      }
    });
    cardreader.on("reconnect", (error) => {
      this.$notify({
        title: "提示",
        message: "实时通信服务正在检测状态更新",
      });
      // console.log("正在重连:", error);
    });
    cardreader.on("error", (error) => {
      that.$notify({
        message:
          that.$t("global.ws.text") +
          " " +
          that.$t("menuitems.clouds.nfcreader.connectfail"),
        type: "error",
        duration: 0,
      });
      console.log("连接失败:", error);
    });
    // that.initsocket();
    // }
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
          console.log('Service Worker registered with scope:', registration.scope);
        }).catch(error => {
          console.error('Service Worker registration failed:', error);
        });
      });
    }
  },
  methods: {
    searchcarduid(hex) {
      var that = this;
      let userinfo = JSON.parse(localStorage.getItem("user"));
      that.$message(that.$t("global.loading.reading"));
      that.$axios
        .post(
          that.apiuri,
          {
            action: "finduser",
            hex: hex,
          },
          {
            headers: { Openid: userinfo.openid },
          }
        )
        .then(function (response) {
          let res = response.data;
          if (res.status == 200) {
            that.$message({
              message: that.$t("global.ws.finduser"),
              type: "success",
            });
            that.$router.push({
              path: "/user/detail",
              query: { id: res.data.id },
            });
          } else if (res.status == 202) {
            that.cardcheckout(hex);
          } else if (res.status == 408) {
            that.$message({
              message: that.$t("menuitems.setting.admins.readcard"),
              type: "warning",
            });
          } else {
            let route = that.$route.path;
            if (route.includes("user/add")) {
            } else {
              that.$router.push({
                path: "/user/add",
                query: { id: hex },
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    cardcheckout(hex) {
      var that = this;
      let userinfo = JSON.parse(localStorage.getItem("user"));
      that.$message.error(that.$t("global.loading.reading"));
      that
        .$confirm(
          that.$t("device.order.checkouttip"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "checkout",
                hex: hex,
              },
              {
                headers: { Openid: userinfo.openid },
              }
            )
            .then(function (response) {
              that.$router.push({ path: "/device/orders" });
              if (response.data.status == 200) {
                let checkout_order_id = response.data.orderid;
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
                that.$axios
                  .post(
                    that.apiuri,
                    {
                      action: "getprintersetting"
                    },
                    {
                      headers: { Openid: that.userinfo.openid },
                    }
                  )
                  .then(function (response) {
                    that.loading = false;
                    if (response.data.status == 200) {
                      if (response.data.setting.status === true && response.data.setting.autoprint === true) {
                        that.$axios
                          .post(
                            that.apiuri,
                            {
                              action: "orderprint",
                              id: checkout_order_id,
                            },
                            {
                              headers: { Openid: that.userinfo.openid },
                            }
                          )
                          .then(function (response) {
                            that.$toast.clear();
                            if (response.data.status == 200) {
                              that.$message({
                                message: that.$t("global.printer_success"),
                                type: "success",
                              });
                              that.getorderinfo();
                            } else if ((response.data.status = 404)) {
                              that.$message.error(that.$t("device.orders.info.noprint"));
                            } else {
                              that.$message.error(that.$t("global.failed"));
                            }
                          })
                          .catch(function (error) {
                            console.log(error);
                          });
                      }
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => { });
    },
    bindcard(hex) {
      var that = this;
      let userinfo = JSON.parse(localStorage.getItem("user"));
      that.$message.error(that.$t("global.loading.reading"));
      that
        .$confirm(
          that.$t("global.ws.userbindcard"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "bindnewcard",
                hex: hex,
                uid: that.$route.query,
              },
              {
                headers: { Openid: userinfo.openid },
              }
            )
            .then(function (response) {
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.ws.bindsuccess"),
                  type: "success",
                });
                that.$router.push({
                  path: "/user/list",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => { });
    },
    async openserial() {
      var that = this;

      const port = await navigator.serial.requestPort();
      await port.open({ baudRate: 9600 });
      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable.getReader();
      while (true) {
        const { value, done } = await reader.read();
        let strdata;
        if (done) {
          // 允许稍后关闭串口。
          reader.releaseLock();
          break;
        }
        // value 是一个 Uint8Array
        if (value.includes("=")) {
          let bitdata = value.split("=");
          let cardhex = bitdata[1].replace(/[\r\n]/g, "");
          cardhex = cardhex.replace(/\n/g, "");
          cardhex = cardhex.replace(/\r/g, "");

          let route = that.$route.path;
          if (route.includes("user/detail")) {
            that.bindcard(cardhex);
          } else if (route.includes("setting/admins")) {
            that.$bus.$emit('cardData', cardhex);
          } else {
            that.searchcarduid(cardhex);
          }
        }
      }
    },
    getotpstatus() {
      this.$axios
        .post(this.apiuri, { action: 'getotpstatus' })
        .then((response) => {
          if (response.data.status === 200) {
            this.otp_enable = true;
          }
        })
        .catch((error) => {
          console.error('获取 OTP 状态失败:', error);
        });
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 45px !important;
}
</style>